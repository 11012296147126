<template>
  <div>
    <Loading is-full-page :active="fetching" />

    <div class="mb-3">
      <h2>Store Settings</h2>
      <br />
      <a-button type="primary" icon="upload" @click="showUploadForm = true">
        {{ $t('settingsStore.uploadImage') }}
      </a-button>
    </div>

    <a-menu
      mode="horizontal"
      class="mb-3"
      :selected-keys="[type]"
      :default-selected-keys="[type]"
      @click="onMenuChange"
    >
      <a-menu-item v-for="(item, key) in types" :key="key">
        {{ item.title }}
      </a-menu-item>
    </a-menu>

    <!-- <div class="mb-5">
      <a-button
        type="primary"
        icon="upload"
        @click="showUploadForm = true"
      >
        {{ $t('settingsStore.uploadImage') }}
      </a-button>
    </div> -->

    <a-empty v-if="!fetching && !imageList.length && !error" />
    <AResult
      v-if="error"
      :status="String(error.status || 500)"
      :title="error.status || 500"
      :sub-title="error.message"
    />

    <a-row type="flex" :gutter="[24, 24]">
      <a-col
        v-for="image in imageList"
        :key="image.image_id"
        :xs="24"
        :md="12"
        :xl="8"
        class="image-col"
      >
        <div class="image-item">
          <div v-if="activeType.id !== 1" class="image-action">
            <a-popconfirm
              title="Are you sure want to delete this item?"
              @confirm="remove(image)"
            >
              <a-button v-if="imageList?.length > getMaxTotalOfImages.min" icon="delete" size="small" type="danger" />
            </a-popconfirm>
          </div>
          <img slot="cover" :src="`${image.url}?tr=w-600`" />
        </div>
      </a-col>
    </a-row>

    <a-modal
      :visible="showUploadForm"
      :title="$t('settingsStore.uploadImage')"
      :width="375"
      :footer="false"
      @cancel="showUploadForm = false"
    >
      <UploadImageForm
        v-if="showUploadForm"
        :types="
          Object.values(types).map(item => ({
            value: item.id,
            title: item.title,
          }))
        "
        :selected-type="activeType.id"
        @saved="saved()"
      />
    </a-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loading from 'vue-loading-overlay'
import { getBusinessMetaData } from '@/api/business'
import UploadImageForm from './components/UploadImageForm'

const DEFAULT_TYPE = 'logo'
const DEFAULT_TABS_WEB_MANAGEMENT = '{"logo":{"id":1,"title":"Logo","is_active":true,"total_of_images":{"max":1,"min":1},"recommended_dimensions":{"width":280,"heigh":186}},"banner":{"id":2,"title":"Home Banner","is_active":true,"total_of_images":{"max":10,"min":1},"recommended_dimensions":{"width":1180,"heigh":334}},"category-banner":{"id":3,"title":"Category Banner","is_active":false,"total_of_images":{"max":10,"min":1},"recommended_dimensions":{"width":1180,"heigh":334}},"reseller-banner":{"id":12,"title":"Reseller Banner","is_active":false,"total_of_images":{"max":10,"min":1},"recommended_dimensions":{"width":1180,"heigh":334}}}'
const DEFAULT_TABS_WEB_STORE_SETTING = '{"logo":{"id":1,"title":"Logo","is_active":true,"total_of_images":{"max":1,"min":1},"recommended_dimensions":{"width":280,"heigh":186}},"banner":{"id":2,"title":"Home Banner","is_active":true,"total_of_images":{"max":10,"min":1},"recommended_dimensions":{"width":1180,"heigh":334}},"category-banner":{"id":3,"title":"Category Banner","is_active":true,"total_of_images":{"max":10,"min":1},"recommended_dimensions":{"width":1180,"heigh":334}},"reseller-banner":{"id":12,"title":"Reseller Banner","is_active":true,"total_of_images":{"max":10,"min":1},"recommended_dimensions":{"width":1180,"heigh":334}}}'

export default {
  name: 'SettingStore',
  components: {
    Loading,
    UploadImageForm,
  },
  data: function () {
    return {
      error: false,
      showUploadForm: false,
      types: null,
    }
  },
  computed: {
    ...mapState('web_platform_channel/store_settings', ['images', 'fetching']),
    type: function () {
      return this.$route.query.type || DEFAULT_TYPE
    },
    activeType: function () {
      return this.types[this.type]
    },
    imageList: function () {
      return this.images
    },
    getNameUrl() {
      if (this.$route.path.includes('web-management')) {
        return 'web-management.web-store-setting'
      } else {
        return 'web-store-setting.id'
      }
    },
    getMaxTotalOfImages() {
      return this.types[this.type].total_of_images || { min: 1, max: 10}
    },
  },
  watch: {
    'types': {
      immediate: true,
      handler(value) {
        if (value) {
          this.fetch()
        }
      },
    },
    '$route.query.type': {
      immediate: true,
      handler(value) {
        if (value) {
          this.fetch()
        }
      },
    },
    '$route.path': {
      immediate: true,
      handler(value) {
        if (value) {
          this.fetchBusinessMetaData(this.$route.path.split('/')[1].replaceAll('-', '_'))
        }
      },
    },
  },
  methods: {
    onMenuChange({ key }) {
      this.$router.push({
        name: this.getNameUrl,
        query: { ...this.$route.query, type: key },
      })
    },
    fetch() {
      this.$store
        .dispatch('web_platform_channel/store_settings/GET_BANNER', {
          type_id: this.activeType.id,
          ...this.$route.params,
          ...this.$route.query,
        })
        .catch(err => {
          this.error = err
        })
    },
    remove(item) {
      this.$store
        .dispatch('web_platform_channel/store_settings/REMOVE_BANNER', {
          img_id: item.image_id,
          type_id: this.activeType.id,
          ...this.$route.query,
          ...this.$route.params,
        })
        .then(() => {
          this.fetch()
        })
    },
    saved() {
      this.showUploadForm = false
      this.fetch()
    },
    async fetchBusinessMetaData(pathname) {
      const businessId = this.$route.query.business_id
      await getBusinessMetaData({ business_id: businessId })
      .then(({ data: response }) => {
        let tabsMetaValue
        response.forEach((item) => {
          if (item.meta_name === `tabs_${pathname}`) {
            tabsMetaValue = JSON.parse(item.meta_value)
          }
        })
        if (!tabsMetaValue) {
          if (`tabs_${pathname}` === 'tabs_web_store_setting') {
            tabsMetaValue = JSON.parse(DEFAULT_TABS_WEB_STORE_SETTING)
          } else {
            tabsMetaValue = JSON.parse(DEFAULT_TABS_WEB_MANAGEMENT)
          }
        }

        this.types = Object.entries(tabsMetaValue)
          .filter((obj) => obj[1].is_active === true)
          .reduce((acc, [key, value]) => {
            acc[key] = value == null ? '' : value;
            return acc;
          }, {});
        if (!this.types[this.type]) {
          this.$router.replace({
            name: this.getNameUrl,
            query: { ...this.$route.query, type: DEFAULT_TYPE },
          })
        }
        if (!this.$router.query?.type) {
          this.$router.replace({
            name: this.getNameUrl,
            query: { ...this.$route.query, type: DEFAULT_TYPE },
          })
        }
      })
      .catch(err => {
        console.error(err)
      })
    },
  },
}
</script>

<style scoped lang="scss">
img {
  max-width: 100%;
}

.image-col {
  display: flex;
  justify-items: center;
}

.image-action {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  opacity: 0.6;
  transition: all 0.2s ease-in-out;
}

.image-item {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #efefef;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  &:hover {
    .image-action {
      opacity: 1;
    }
  }
}
</style>
