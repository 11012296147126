<template>
  <a-form-model ref="ruleForm" :model="form">
    <a-form-model-item ref="form" prop="type" label="Image Type" :rules="[{ required: true }]">
      <a-select
        v-if="types.length"
        v-model="form.type"
        name="type"
        placeholder="Select Image Type"
        style="max-width: 200px; width: 100%"
        @change="onSelectType"
      >
        <a-select-option
          v-for="typeItem in types"
          :key="typeItem.value"
          :value="typeItem.value"
        >
          {{ typeItem.title }}
        </a-select-option>
      </a-select>
    </a-form-model-item>

    <a-form-model-item prop="image" label="Choose image" :rules="[{ required: true }]">
      <a-upload
        v-model="form.image"
        :show-upload-list="false"
        name="image"
        list-type="picture-card"
        class="avatar-uploader"
        style="width: fit-content !important;"
        :before-upload="() => false"
        @change="onFileChange"
      >
        <img v-if="preview" class="w-100" :src="preview" alt="" />
        <div v-else>
          <a-icon type="plus" />
          <div class="ant-upload-text">
            Upload
          </div>
        </div>
        <div v-if="preview && pictUploadDimension" class="disclaimer-dimension mt-2">
          The dimensions of the photo you uploaded are: {{ pictUploadDimension }}
          <br />
        </div>
        <div v-if="form.type" class="disclaimer-dimension mt-2">
          <b>the recommended dimensions for {{ getSelectedTypeName }} are: {{ recomendationDimension }}</b>
        </div>
      </a-upload>
    </a-form-model-item>

    <a-form-model-item prop="redirectUrl" label="Redirect URL">
      <a-input
        v-model="form.redirectUrl"
        placeholder="optional"
        name="redirectUrl"
      />
    </a-form-model-item>

    <div class="mt-5">
      <a-button
        type="primary"
        block
        size="large"
        icon="upload"
        :loading="saving"
        @click="onHandleSubmit"
      >
        {{ $t('settingsStore.uploadImage') }}
      </a-button>
    </div>
  </a-form-model>
</template>

<script>
export default {
  name: 'UploadImageForm',
  props: {
    types: {
      type: Array,
      required: true,
      default() {
        return []
      },
    },
    selectedType: {
      type: [String, Number],
      default() {
        return null
      },
    },
  },
  data() {
    return {
      form: {
        type: '',
        image: null,
        redirectUrl: '',
      },
      type: this.selectedType,
      img: null,
      redirectUrl: null,
      preview: null,
      saving: false,
      pictUploadDimension: null,
      recomendationDimension: null,
      getSelectedTypeName: '',
    }
  },
  computed: {
  },
  watch: {
    selectedType: function () {
      this.type = this.selectedType
    },
    img: function () {
      if (!this.img) {
        this.preview = null
        return
      }

      const reader = new FileReader()
      reader.onload = e => {
        this.preview = e.target.result
      }

      reader.readAsDataURL(this.img)
    },
  },
  methods: {
    onSelectType(e) {
      this.img = null
      this.preview = null
      this.pictUploadDimension = null
      if (e === 1) {
        this.recomendationDimension = '280x186 px'
      } else {
        this.recomendationDimension = '1180x334 px'
      }
      const findSelectedType = this.types.find((obj) => obj.value === e)
      this.getSelectedTypeName = findSelectedType?.title || ''
    },
    async onFileChange({ file }) {
      this.img = file
      this.form.image = file
      const u = URL.createObjectURL(file);
      const uploadedFile = new Image;
      const tempPictDimension = await this.addImageProcess(file)
      if (this.form.type == 1 && tempPictDimension.width !== 280 && tempPictDimension.height !== 186) {
        this.pictUploadDimension = `${tempPictDimension.width}x${tempPictDimension.height} px`
      } else if (this.form.type == 2 && tempPictDimension.width !== 1180 && tempPictDimension.height !== 334) {
        this.pictUploadDimension = `${tempPictDimension.width}x${tempPictDimension.height} px`
      } else {
        this.pictUploadDimension = null
      }
    },
    addImageProcess(file){
      const imgUrl = URL.createObjectURL(file);
      return new Promise((resolve, reject) => {
        let img = new Image()
        img.onload = () => resolve({
          height: img.height,
          width: img.width,
        })
        img.onerror = reject
        img.src = imgUrl
      })
    },
    async onHandleSubmit() {
      this.$refs.ruleForm.validate(async valid => {
        if (!valid) return
        this.saving = true
        if (this.form.type == 2) {
          const totalBannerImages = await this.$store.dispatch('web_platform_channel/store_settings/CHECKIMAGEBANNER', {
            type_id: this.form.type,
            ...this.$route.params,
            ...this.$route.query,
          })
          if (totalBannerImages.length < 10) {
            delete this.$route.query.type
            this.$store
              .dispatch('web_platform_channel/store_settings/UPLOAD_BANNER', {
                file: this.form.image,
                type: this.form.type,
                redirectUrl: this.form.redirectUrl,
                ...this.$route.params,
                ...this.$route.query,
              })
              .then(() => {
                this.$emit('saved')
              })
              .finally(() => {
                this.saving = false
              })
          } else {
            this.saving = false
            this.$notification.error({
              message: 'Gagal',
              description: 'Jumlah maksimal banner yang bisa diupload adalah 10',
            })
          }
        } else {
          delete this.$route.query.type
          this.$store
            .dispatch('web_platform_channel/store_settings/UPLOAD_BANNER', {
              file: this.form.image,
              type: this.form.type,
              redirectUrl: this.form.redirectUrl,
              ...this.$route.params,
              ...this.$route.query,
            })
            .then(() => {
              this.$emit('saved')
            })
            .finally(() => {
              this.saving = false
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.ant-form-item {
  margin-bottom: 1rem;
  padding-bottom: 0;

  &-label {
    line-height: 1.5;
  }
}
.disclaimer-dimension {
  color: var(--kit-color-primary);
  font-size: 10px;
}
</style>
<style lang="scss">
.avatar-uploader {
  .ant-upload-select-picture-card {
    width: 100% !important;
    img {
      max-width: 100%;
    }

    i {
      font-size: 32px;
      color: #999;
      padding: 0px 25px;
    }
    .ant-upload-text {
      margin-top: 8px;
      color: #666;
    }
  }
}
</style>
